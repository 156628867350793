<template>
  <v-container fluid>
    <v-card
      outlined
      elevation="1"
      class="pa-2"
    >
      <v-row justify="end">
        <v-col cols="auto">
          <v-btn
            color="primary"
            elevation="1"
            small
            :loading="downloading"
            @click="downloadCsv"
            :disabled="!this.headers.length || downloading"
          >
            Download
          </v-btn>
        </v-col>
      </v-row>
      <v-divider
        class="my-4"
        v-if="
          $route.query.fromdate
            || $route.query.todate
        "
      ></v-divider>
      <v-row>
        <v-col class="pl-6">
          <p class="body-2" v-if="$route.query.fromdate && $route.query.todate">
            Tanggal : {{dayjs($route.query.fromdate).format('DD-MM-YYYY')}} - {{dayjs($route.query.todate).format('DD-MM-YYYY')}}
          </p>
          <p class="body-2" v-else-if="$route.query.todate">
            Tanggal : {{dayjs($route.query.todate).format('DD-MM-YYYY')}}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-divider></v-divider>
          <v-data-table
            :loading="isLoading"
            loading-text="Loading ..."
            :headers="headers"
            :items="items"
            :server-items-length="totalItems"
            :options.sync="pagination"
            :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
          >
            <template v-slot:[`footer.page-text`]="props">
              <span>
                {{$route.query.headerTitle || ''}}
                <span v-if="items.length">
                  {{props.pageStart}}-{{props.pageStop}} dari {{props.itemsLength}}
                </span>
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';

const { exportCSVFile } = require('../../helper/csvGenerator');

export default {
  name: 'report-detail',
  mounted() {
    const { reportParameter } = this.$route.query;
    this.reportParameter = reportParameter ? reportParameter.split('|').toString().replace(/\s/g, '').split(',')
      .map((reportParam) => reportParam) : [];
  },
  watch: {
    pagination: {
      handler(newVal) {
        const { page, itemsPerPage } = this.$route.query;
        if ((newVal.page !== +page) || (+newVal.itemsPerPage !== +itemsPerPage)) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              page: newVal.page,
              itemsPerPage: newVal.itemsPerPage,
            },
          });
        }
        this.fetchReport();
      },
      deep: true,
    },
  },
  data() {
    return {
      isLoading: false,
      downloading: false,
      headers: [],
      items: [],
      pagination: {
        itemsPerPage: +this.$route.query.itemsPerPage || 10,
        page: +this.$route.query.page || 1,
        sortBy: [],
        sortDesc: [],
      },
      totalItems: 0,
      reportParameter: [],
    };
  },
  methods: {
    dayjs,
    toggleLoading(boolean) {
      if (this.items.length > 0) {
        this.isLoading = false;
        return boolean ? this.$root.$loading.show() : this.$root.$loading.hide();
      }
      this.$root.$loading.hide();
      this.isLoading = boolean;
    },
    async fetchReport() {
      const { id } = this.$route.params;
      const { page, itemsPerPage } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
      };
      this.reportParameter.forEach((param) => {
        if (this.$route.query[param]) filters[param] = this.$route.query[param];
      });
      try {
        this.toggleLoading(true);
        const result = await this.$_services.report.previewReport(id, filters);
        this.headers = [];
        if (result.contents.length) {
          Object.keys(result.contents[0]).forEach((textHeader) => {
            this.headers.push({
              text: textHeader,
              value: textHeader,
              class: 'grey--text accent-3',
              width: '200px',
              sortable: false,
            });
          });
          this.items = result.contents;
        }
        this.totalItems = result.totalData;
      } finally {
        this.toggleLoading(false);
      }
    },
    async downloadCsv() {
      try {
        const { id } = this.$route.params;
        this.downloading = true;
        const filters = {};
        this.reportParameter.forEach((param) => {
          if (this.$route.query[param]) filters[param] = this.$route.query[param];
        });
        const result = await this.$_services.report.exportReportToCSV(id, filters);
        exportCSVFile(null, [[result]], this.$route.query.headerTitle);
      } finally {
        this.downloading = false;
      }
    },
  },

};
</script>
